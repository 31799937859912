$.Valid = function (el) {

    var $el = el;

    if (!$el.length) return;
    var validator = $el.validate({
        rules: {
            
            username: {
                required: true,
                minlength: 4,
                pwcheck: true
            },
            password1: {
                required: true,
                minlength: 4,
                pwcheck: true
            },
            password_confirmation: {
                required: true,
                minlength: 4,
                equalTo: '[name="password"]'
            },
            tel: {
                required: true,
                minlength: 18,
            },
            phone: {
                required: true,
                checkMask: true
            },
            check: {
                required: true
            },
            textarea: {
                required: true
            },
            message: {
                required: true
            },
            date: {
                required: true
            },
            from: {
                required: true,
                minlength: 16 
            },
            'c[]': {
                required: true
            },
            code1: {
                required: false
            },
            'code[]': {
                required: true
            },
            email: {
                required: true,
                mailVal: true
            },
            submitHandler: function (form) {
                if($(form).hasClass('modal__form')){
                    console.log(5555);
                    setTimeout(function() {
                        form.submit();
                    }, 2500); 
                }
            }
        },
        messages:{
			from:{
				required: " ",
				minlength: " ",
			},
		}
    })
    $.validator.addMethod("pwcheck", function(value) {
       return /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) 
        //    && /[a-z]/.test(value) 
        //    && /\d/.test(value) 
    }, "Incorrect Password!");
    $.validator.addMethod("mailVal", function(value) {
       return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) 
    }, "Incorrect Password!");
    $.validator.addMethod("checkMask", function(value) {
         return $('.phone-masked.ok').length;
    });
};

$('.Valid:not(.noval)').each(function(){
    $.Valid($(this));
});
